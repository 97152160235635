*{
    font-family: Manuale;
}

.fetch-container{
    display: block;
    max-width: 100%;
    max-height: 100%;
    
}

.flex-container{
    display: flex;
    
}
.fetch-sidebar{
    margin-top: -10%;
}

.fetch-middle{
    margin-left: -70%;
    align-items: center;
}

.pd-container{
    width: 20vw;
    height: 25vh;
    background-color: #fff;
    border-radius: 15px;
    padding-left: 1%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.payment-details{
    width: 20vw;
    height: 25vh;
    margin-top: 2.5%;
    margin-right: -50%;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}
.posts{
    width: 100%;
    display: flex;
    padding-top: 1%;
    margin-top: 2%;
    gap: 20%;
    margin-left: 10%;
    
}

.pd-title , .pd-data{
    margin-left: 8%;
    margin-top: 5%;
    padding-top: 5%;
   
}

.pd-data h5{
    margin-top: -1px;
    font-size: 18px;
}

.pd-data label , .pd-title{
    font-weight: bold;

}

.btn-container{
    width: 100%;
    display: flex;
    margin-left: 55%;
   margin-top: 8%;
}
    
button{
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: #fff;
}

.app-btn{
    background-color: #326346;
    margin-right: 20px;
}

.deny-btn{
    background-color: #DA1E28;
}
.donation-image{
    width: 450px;
    height: 350px;
    margin-top: 24%;
    margin-left: -30%;
    
}

.pay-title , .pay-data{
    margin-left: 8%;
    margin-top: 5%;
    padding-top: 5%;

}

.pay-data h5{
    font-size: 18px;
}

.pay-data{
    margin-top: -5%;
}

.pay-title{
    font-weight: bold;
    margin-top: -5px;
    margin-bottom: 25px;
}

.pay-data h5 label{
    font-weight: bold;
}


.top-nav{
    margin-left: -10%;
    margin-top: -4%
}