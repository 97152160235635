.admin-dashboard-page{
    margin-top: -4%;
    width: 100%;
}

.botom-container-admindashboard{
    display: flex;
}
.top-nav-container-admindashboard{
    margin-left: -10%;
    margin-top: -3%;
}
.side-nav-container-admindashboard{
    margin-top: -10%;
}
.admin-dashboard-container{
    width: 100%;
    height: 40%;
  
}
.admin-dash-first-row{
    display: flex;
    margin-left: -110%;
    margin-top: 10%;
}

.dash-obituary-card ,
.dash-donation-card ,
.dash-remembrance-card{
    width: 180px;
    height: 100px;
    background-color: #fff;
    margin-left: 50px;
    border-radius: 6%;
    box-shadow:1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    margin-top: -3%;
    text-align: center;
    padding-top: 1%;
    color: darkgreen;
}
.admin-dash-image{
    margin-left: 12%;
    margin-top: -4%;
}
.admin-dash-image img{
    width: 220px;
    height: 200px;
}
.admin-dash-second-row{
    display: flex;
    margin-left: -110%;
    margin-top: 10%;
}
.dash-annualIncome-card,
.dash-Posts-card{
    width: 400px;
    height: 300px;
    margin-top: -5%;
    background-color: #fff;
    margin-left: 50px;
    border-radius: 6%;
    box-shadow:1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.dash-Posts-card{
    margin-left: 10%;
}
.donation-count{
    font-size: 20px;
    font-weight: bold;
   margin-top: 5%;
}