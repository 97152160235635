.full-footer{
    width: 100%;
    display: block;
    margin-left: -4.9%;
}

.upper-footer{
    width: 100%;
    height: 60px;
    background-color: black;
    position: absolute;
    color: #fff;
  
}   

.lower-footer{
    width: 100%;
    height: 30px;
    position: absolute;
    background-color: #696969;
    margin-top: 4%;
    color: #fff;
    
}
.footer-link-nav{
    width: 50vw;
    height: auto;
    

}
.footer-link-ul{
    
    display: flex;
    list-style: none;
   justify-content: center;
   

}
.footer-link-li{
    margin-right: 5%;
}

.footer-links{
    position: absolute;
    margin-left: 25%;
    margin-top: 1%;
    
}

.footer-links .footer-link-a{
  text-decoration: none;
  color: #fff;
  font-size: 17px;
}

.lower-footer-text{
    position: absolute;
    display: flex;
   margin-left: 42%;
   font-size: 16px;
}

.copyright::before{
    content: "\00A9";
    margin-left: 1.5px;
    margin-right: 1.5px;
}