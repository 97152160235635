/* Forgotpassword.css */

.forgot-password-container {
  position: absolute;
  width: 1440px;
  height: 1886px;
  left: 0px;
  top: 0px;
  
  background: #F2F2F8;
}

.forgot-password-card {
  position: absolute;
    width: 578px;
    height: 850px;
    left: 91px;
    top: 225px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.forgot-password-header {
  color: #326346;
    position: absolute;
width: 622px;
height: 53px;
left: 475px;
top: -90px;
font-family: 'Manuale';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 52px;
display: flex;
align-items: center;
text-align: center;

}

.custom-line-for{
  position:absolute;
  width: 1000px;
  height: 16.54px;
  left: 200px;
  top: -50px;
  border: -1px solid #070707;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
}

.header3{
  color: #326346;
  position:absolute;
  height: 16.54px;
  top: 20px;
  left: 200px;
  width: 1000px;

}

.header4{
  position:absolute;
  top: 55px;
  width: 300px;
  left: 150px;


}

.email{
  position: absolute;
width: 389px;
height: 46.51px;
left: 75px;
top: 150px;
border-radius: 10px;
border: none;
}

.email1{
  position: absolute;
width: 389px;
height: 46.51px;
left: 75px;
top: 200px;
background: #D9E2E7;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
border-color: #D9E2E7;
border: none;
}

.send-btn{
  top: 215px;
  position: absolute;
  color: #326346;
  left: 500px;


}

.otp{
  position: absolute;
  width: 389px;
  height: 46.51px;
  left: 75px;
  top: 300px;
  border-radius: 10px;
  border: none;
  font-size: 150%;
  font-weight: 500;
}

.otp1-1{
  position: absolute;
  width: 50px;
  height: 46.51px;
  left: 75px;
  top: 350px;
  background: #D9E2E7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border-color: #D9E2E7;
  border: none;
}

.otp2{
  position: absolute;
  width: 50px;
  height: 46.51px;
  left: 150px;
  top: 350px;
  background: #D9E2E7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border-color: #D9E2E7;
  border: none;
}

.otp3{
  position: absolute;
  width: 50px;
  height: 46.51px;
  left: 225px;
  top: 350px;
  background: #D9E2E7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border-color: #D9E2E7;
  border: none;
}

.otp4{
  position: absolute;
  width: 50px;
  height: 46.51px;
  left:300px;
  top: 350px;
  background: #D9E2E7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border-color: #D9E2E7;
  border: none;
}

.button{
  position: absolute;
    font-family: 'Manuale';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 10px;
    text-align: center;
    color:white;
    background-color: #326346;
    position: absolute;
    width: 368px;
    height: 49.61px;
    left: 100px;
    top: 480px;
    border-radius: 10px;
    border-color: #326346;
    border: none;
}

.b-log{
  position: absolute;
  color: #326346;
  left: 400px;
  top: 580px;
  width: 368px;

}

.img{
  position: absolute;
    width: 570px;
    height: 650px;
    left: 650px;
    top: 50px;
    
    mix-blend-mode: normal;
}