.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_inner {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
}

.close_btn {
    margin: 0 5px auto 350px;
  background: none;
  border: none;
}

.popup-icon-container{
    display: block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto 75px;
    margin-top: -25px;
}

.popup-icon-container-inner{
    display: flex;
}

.popup-icon{
    padding: 10px;
    align-items: baseline;
}

/* .share_button {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.share_button svg {
  margin-right: 10px;
}

.share_button .react-share__ShareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #326346;
  color: white;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.share_button .react-share__ShareButton:hover {
  background-color: #2a513b;
}

.share_button .react-share__ShareButton:focus {
  outline: none;
}

.share_button .react-share__ShareButton:active {
  background-color: #1e3a29;
}

.share_button .react-share__ShareButton svg {
  fill: white;
}

.share_button .react-share__ShareButton.round {
  border-radius: 50%;
}

.share_button .react-share__ShareButton.round svg {
  fill: white;
} */