*{
    font-family: Manuale;
  }

  body{
    max-width: 100%;
    background-color:#F2F2F8;
    background-image: none;
    padding: 5%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  /*Add donations*/


  .add-donation-page{
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    position: absolute;
    display: block;

  
  }
  .add-donation-header-conatin{
    width: 90%;
  }

  .add-donation-input{
    background-color: #fff;
  }

  .add-donation-form-conatin{
    position: absolute;
    display: block;
    margin-top: 2%;
  }
  
  .add-donation-title h1{
    width: 100%;
    color: #326346;
    font-weight: bold;
    font-size: 34px;
    margin-left: 5%;
  }
.add-donation-form{
  width: 50vw;
  height: 80vh;
  margin-left: 20vw;
  position: absolute;
  margin-top: 8%;
  background-color: #D9D9D9;
  border-radius: 20px;
  display: block;
  
}



.donation-form-heading h2{
  position: absolute;
  color: #326346;
  margin-top: 5%;
  margin-left: 5%;
}

.add-donation-form hr{
  position: absolute;
  color: #326346;
  width: 85%;
  margin-top: 10%;
  margin-left: 5%;
  border-bottom: 2px solid #326346;
}

.add-donation-footer-contain{
  margin-top: 60%;
}
.donation-form-data{
  position: absolute;
  display: block;
  margin-top: 15%;
  margin-left: 20%;
  width: 100%;
}
.donation-form-data label{
  font-size: 20px;
}
.donation-submit-btn{
  position: absolute;
  margin-top: 68%;
  margin-left: 45%;
}
.add-donation-form input{
  border-radius: 10px;
  border: none;
}
.donation-form-data-first3{
  display: flex;
}

.donation-form-data-first3-label{
  display: block;
  margin-bottom: 15px;
}
.donation-form-data-first3-input{
  margin-left: 10%;
  
}

.donation-form-data-first3-input input{
  margin-bottom: 2%;
  padding: 2px;
  width: 125%;
  padding-inline-start: 10px;
}

#comment{
  width: 55%;
  height: 80px;
  padding-inline-start: 10px;
}

.donation-form-name{
  display: flex;
}

.donation-form-message{
  margin-top: 2%;
}

.type-label-inputs{
  margin-left: 5%;
}

#radio{
  margin-left: 2%;
}
  /*Donation user*/


  .view-donation-frame{

    width: 100%;
    height: 100%;
    margin-left: 20%;
  
 

  }
.view-donation-sliding-content{
  display: flex;
}
.sliding-arrow{
  margin-top: 16%;
}
  .leftArrow , .rightArrow{
    width: 30px;
    height: 30px;
  }
  .leftArrow{
    position: absolute;
  }

  .rightArrow{
   margin-left: 10%;
  }

  .donation-frame {
    color: black;
    margin-bottom: 2%;
    background-image: url(../images/flowerframe.jpg);
    background-repeat: no-repeat;
    background-size:contain;
    font-family: Manuale;
  }
  
  .donation-frame p{
    width: 60%;
    height: 62%;
    margin-left: 10%;
    padding-top: 18%;
    font-size: 18px;
    text-align: justify;
  }
  
  .h6{
    margin-left: 50%;
    padding-bottom: 3px;
  }
  
  .donation-hr{
    margin-top: 8px;
    border: 2px solid rgb(5, 5, 5);
    color: black;
    
    width: 66%;
   
  }

  .headlink{
 
    margin-left: -14%;
    margin-top: 4%;
    font-size: 30px;
    margin-left: 6px;
  }

  .headlink a{
    font-size: 30px;
    margin-left: 0.5%;
  }

  .donation-submit-btn button{
    background-color: #326346;
  }

  