.page{
    position: absolute;
    width: 1440px;
    height: 950px;
    left: 0px;
    top: 0px;
    background: #F5F5FA; 
}

.social-icon {
    position: absolute;
    width: 570px;
    height: 650px;
    left: 650px;
    top: 220px;
    
    mix-blend-mode: normal;
    
}

.wrapper {
    position: absolute;
    width: 578px;
    height: 650px;
    left: 91px;
    top: 225px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

}

.head {
    color: #326346;
    position: absolute;
width: 622px;
height: 53px;
left: 475px;
top: 100px;

font-family: 'Manuale';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 52px;
display: flex;
align-items: center;
text-align: center;

}

.custom-line{
    position:absolute;
    width: 1000px;
    height: 16.54px;
    left: 200px;
    top: 150px;
    border: -1px solid #070707;
    
}



.login1{
    left: 250px;
    color: #326346;
    position: absolute;
    font-family: 'Manuale';
    height: 1700px;
    top: 10px;
}

.font{
    position: absolute;
    width: 139px;
    height: 17px;
    left: 50px;
    top: 80px;
    font-family: 'Manuale';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    display: flex;
    align-items: center;
    color: #1F1C1C;
}


.un {
position: absolute;
width: 389px;
height: 46.51px;
left: 50px;
top: 110px;
background: #D9E2E7;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
border: none;
    
}

.passw{
position: absolute;
width: 130.49px;
height: 16.54px;
left: 50px;
top: 175px;
font-family: 'Manuale';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 34px;
display: flex;
align-items: center;
color: #1F1C1C;
}

.un1{
position: absolute;
width: 389px;
height: 46.51px;
left: 50px;
top: 200px;
background: #D9E2E7;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
border-color: #D9E2E7;
border: none;

}



.re{
position: absolute;
width: 180.67px;
height: 16.54px;
left: 50px;
top: 270px;
font-family: 'Manuale';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 29px;
display: flex;
align-items: center;
color: #1F1C1C;
}

.login-btn{
    position: absolute;
    font-family: 'Manuale';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 10px;
    text-align: center;
    color:white;
    background-color: #326346;
    position: absolute;
    width: 368px;
    height: 49.61px;
    left: 100px;
    top: 320px;
    border-radius: 10px;
    border-color: #326346;
    border: none;


      }

.forget{
position: absolute;
width: 192.94px;
height: 16.54px;
left: 363px;
top: 400px;
font-family: 'Manuale';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 29px;
display: flex;
align-items: center;
text-align: right;
color: #1F1C1C;
}

.donot {
    position: absolute;
    width: 322px;
    height: 16.54px;
    left: 146px;
    top: 600px;
    font-family: 'Manuale';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    

}

.sign{
    position: absolute;
width: 93px;
height: 17px;
left: 218px;
top: -7px;

font-family: 'Manuale';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 34px;
display: flex;
align-items: center;

color: #326346;
}

.or-line{
    position: absolute;
    top: -100px;
    color:#070707;

}

.line3{
    position: absolute;
width: 200px;
height: 16.54px;
left: 68px;
top: 543px;
border: -1px solid #070707;
}

.line4{
    position: absolute;
    width: 200px;
    height: 16.54px;
    left: 310px;
    top: 443px;
    border: -1px solid #070707;

}

.or{
    position: absolute;
    width: 2px;
    height: 10px;
    left: 272px;
    top: 558px; 
    font-family: 'Manuale';
font-style: normal;
font-weight: 70;
font-size: 2px;
line-height: 3px;
display: flex;
align-items: center;
}


.s-icon{
    position: absolute;

    top: -105px;
}

.fb {
    position: absolute;
    width: 44.01px;
    height: 42.42px;
    left: 265px;
    top: 620px;
    
    
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
}

.ins {
    position: absolute;
    width: 44.01px;
    height: 42.42px;
    left:350px;
    top: 620px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;}

 .g {
    position: absolute;
    width: 44.01px;
    height: 42.42px;
    left: 175px;
    top: 620px;
    
    
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
}

.social-icon.log img {
    position: absolute;
    right: 100px; /* Adjust the value as needed */
    top: 50%; /* Adjust the value as needed */
    transform: translateY(-50%); /* Center vertically */
    width: 300px; /* Adjust the width as needed */
  height: 300px; /* Adjust the height as needed */
  }

  .login{
position: absolute;
width: 173.98px;
height: 53px;
left: 293px;
top: 480px;
font-family: 'Manuale';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 57px;
text-align: center;
color:white;
background-color: #326346;
position: absolute;
width: 368px;
height: 49.61px;
left: 100px;
top: 325px;
  }


  
/* Include any additional styles for elements not covered here */

/* Adjust media queries if necessary to ensure responsiveness on all devices */

